import React from "react";
import Homeindex from "@/pages/home";
import { Metadata } from 'next'
// import Home1 from "@/pages/home1";

export default function Home() {
  return (
   <Homeindex/>
//    <Home1/>
  );
}
